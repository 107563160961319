import React from 'react';
import { useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faKeyboard } from '@fortawesome/free-solid-svg-icons';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import generatedGitInfo from '../../generatedGitInfo.json';
import packageJson from '../../../package.json';
import { Tooltip } from '../Common/Tooltip/Tooltip';

interface FooterI {
    styles?: React.CSSProperties,
}
export default function Footer(props: FooterI) {
    const { theme } = useContext(ThemeContext);
    return (
        <div id="Footer" style={{
            color: "#bbb",
            padding: "10px 30px",
            borderTop: "1px solid #eee",
            zIndex: 1010,
            // position: "fixed",
            backgroundColor: theme.backgroundPrimary,
            font: "Arial",
            fontSize: "11px",
            textAlign: "right",
            width: "100%",
            bottom: 0,
            ...(props.styles ?? {})
        }}>
            <FontAwesomeIcon icon={faEnvelope} style={{ border: "none", marginRight: "5px" }} />
            <a href="mailto:info@bitgenia.com" style={{ color: theme.link, marginRight: "5px" }}>info@bitgenia.com</a>
            | version: {packageJson.version}&nbsp;-&nbsp;
            <Tooltip label={`${generatedGitInfo.gitCommitHash} - ${generatedGitInfo.gitBranch} - ${generatedGitInfo.gitDate.split(" ")[0]} - ${generatedGitInfo.gitDeveloper}`} style={{ fontSize: "4px" }}>
                <FontAwesomeIcon icon={faKeyboard} style={{ border: "none", marginRight: "5px" }} />
            </Tooltip>
        </div>
    )
}
