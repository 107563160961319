import React, { useEffect, useState } from 'react';
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { LangContext } from "../../../../../Contexts/Lang";
import QueryContext from "../../../../../Contexts/Query/QueryContext";
import ThemeContext from "../../../../../Contexts/Theme/ThemeContext";
import { PredictorsTemplateTableRowI } from './PredictorsTemplateTable';
import MetapredictorsTable from './MetapredictorsTable';
import ConservationTable from './ConservationTable';
import SplicingTable from './SplicingTable';
import PredictorsTable from './PredictorsTable';
import PredictorsSubtitle from './PredictorsSubtitle';
import BadgeManeSelect from 'src/Components/Common/Badges/BadgeManeSelect';
import ReactSelect from 'src/Components/Common/Form/ReactSelect';
import { LabelValueI } from 'src/utils/BioFunctionalData';
import { VariantTranscriptI } from 'src/Contexts/interfaces';
import { SpliceAiLookup } from 'src/Components/Common/Links/Links';


interface PredictorsCardI {
    infoCd: any
}

export default function PredictorsCard(props: PredictorsCardI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { variant } = useContext(QueryContext);
    // console.log(variant?.data?.transcriptData ?? "")
    const { theme } = useContext(ThemeContext);

    const isMitochondrial = (variant.data?.study?.subtype ?? "").toUpperCase().trim() === "MT-DNA";

    const defaultScoreValue = "--"
    const getEmptyTableRow = (id: string, label?: string): PredictorsTemplateTableRowI => { return { id: id, label: label ?? "", score: defaultScoreValue } }

    const [transcriptActual, settranscriptActual] = useState<VariantTranscriptI>();
    const [transcriptSelect, settranscriptSelect] = useState<LabelValueI>();
    // console.log(transcriptActual)
    let transcriptsOptions: LabelValueI[] = []
    if (variant?.data?.transcriptData && variant?.data?.transcriptData.length > 0) {
        transcriptsOptions = variant?.data?.transcriptData.filter((x: VariantTranscriptI) => x.type !== "RefSeq")
            .map((x: VariantTranscriptI) => { return { label: x.id, value: x.id } })

    }


    const metaPredictorsTable = transcriptActual ? (
        <>
            <PredictorsSubtitle subtitle={translate("common.metapredictors")} style={{ paddingBottom: "5px", margin: "5px" }} />
            <MetapredictorsTable infoCd={props.infoCd} variantTranscript={transcriptActual}
                defaultScoreValue={defaultScoreValue} />
        </>) : undefined
    const predictorsTable = transcriptActual ? (
        <PredictorsTable variantTranscript={transcriptActual}
            defaultScoreValue={defaultScoreValue}
            getEmptyTableRow={getEmptyTableRow} />) : undefined
    const conservationTable = (<ConservationTable infoCd={props.infoCd} defaultScoreValue={defaultScoreValue} />)
    const splicingTable = (<SplicingTable infoCd={props.infoCd} defaultScoreValue={defaultScoreValue} getEmptyTableRow={getEmptyTableRow} />)
    const thereIsPredictors = thereIsPredictionData(props.infoCd)

    const setLabel = (x: VariantTranscriptI) => {
        return (<>
            {x.id}
            {x.isMane && <BadgeManeSelect fontSize={13} />}
        </>)
    }

    const handleSetTranscriptSelect = (transcript: VariantTranscriptI[]) => {
        if (Array.isArray(transcript) && transcript.length > 0) {
            settranscriptSelect({ label: setLabel(transcript[0]), value: transcript[0].id })
        } else {
            const newTranscript = variant?.data?.transcriptData[0] as VariantTranscriptI;
            settranscriptSelect({ label: setLabel(newTranscript), value: newTranscript.id })
        }
    }

    useEffect(() => {
        if (variant?.data?.transcriptData && variant?.data?.transcriptData.length > 0) {
            let transcript = variant?.data?.transcriptData.filter((x: VariantTranscriptI) => x.isMane);
            handleSetTranscriptSelect(transcript);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variant.data])
    useEffect(() => {
        if (variant?.data?.transcriptData && variant?.data?.transcriptData.length > 0 && transcriptSelect) {
            let transcript = variant?.data?.transcriptData.filter((x: VariantTranscriptI) => x.id === transcriptSelect.value);
            if (transcript.length > 0) {
                settranscriptActual(transcript[0]);
            } else {
                transcript = variant?.data?.transcriptData[0]
                settranscriptActual(transcript);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcriptSelect])
    // console.log(transcriptActual)

    return (
        <>
            <Card>
                <Card.Title style={{
                    padding: "7px 10px",
                    fontSize: "17px",
                    color: theme.grayHeader,
                    backgroundColor: theme.backgroundSecondary,
                    borderBottom: "1px solid lightgray",
                    marginBottom: 0
                }}>

                    &nbsp;{translate("common.predictors")}
                    <label style={{ color: "gray", float: "right", fontSize: "10px", textTransform: "uppercase" }}>
                        {!thereIsPredictors && <label style={{ verticalAlign: "middle", marginBottom: "5px" }}>
                            {translate("casesQuery.thereINoPredictionDataAvailable")} &nbsp;&nbsp;&nbsp;
                        </label>}
                        <FontAwesomeIcon icon={faChartLine} style={{ color: "lightgray", margin: "3px 5px 0 0", fontSize: "17px" }} />
                    </label>
                </Card.Title>
                <Card.Body style={{ padding: 0, display: "flex" }}>
                    {!thereIsPredictors ?
                        <Row style={{ padding: 10, margin: 0, width: "100%", fontSize: "12px" }}>
                            <span style={{ fontWeight: "bold", color: "gray" }}>SpliceAI&nbsp;:&nbsp;</span>
                            <span style={{  marginRight: "0px" }}>
                                <SpliceAiLookup
                                    chromosome={variant?.data?.variant?.chromosome}
                                    position={`${variant?.data?.variant?.position}`}
                                    reference={variant?.data?.variant?.reference}
                                    alternative={variant?.data?.variant?.alternative}
                                    hg={variant?.data?.study?.genomeReference}
                                />
                            </span>
                        </Row>
                        :
                        <Row style={{ padding: 0, margin: 0, width: "100%" }}>
                            {transcriptActual && <Col md={12} xl={6} style={{ padding: 10, margin: 0 }}>
                                <Card style={{ border: "none", backgroundColor: theme.background4 }}>
                                    <Card.Header style={{ backgroundColor: theme.backgroundSecondary, fontWeight: "bold", padding: 0 }}>
                                        <ReactSelect options={transcriptsOptions}
                                            onChange={(e: any) => {
                                                let transcript = variant?.data?.transcriptData.filter((x: VariantTranscriptI) => e.value === x.id);
                                                handleSetTranscriptSelect(transcript);
                                            }}
                                            value={transcriptSelect}
                                            placeholder={"Order by"}
                                            // onKeyDown={(e: any) => {

                                            // }} 
                                            divStyles={{ width: "inherit", margin: "3px" }}
                                        />
                                    </Card.Header>
                                    <Card.Body style={{ backgroundColor: theme.background4, padding: 10 }}>
                                        <Row style={{ padding: 0, margin: "0 0 10px 0", width: "100%" }}>
                                            {/* <div style={{ margin: "10px 15px" }}>{metaPredictorsTable}</div> */}
                                            {metaPredictorsTable}
                                        </Row>
                                        {/* <br style={{ margin: "10px 0" }} /> */}
                                        <br />
                                        <Row style={{ padding: 0, margin: 0 }}>
                                            {/* <div style={{ margin: "10px 15px" }}>{predictorsTable}</div> */}
                                            {predictorsTable}
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>}
                            <Col md={12} xl={6} style={{ padding: 10, margin: 0 }}>
                                <Row style={{ padding: 0, margin: 0, width: "100%" }}>
                                    {!isMitochondrial && <>{conservationTable}</>}
                                </Row>
                                <br />
                                <br />
                                <Row style={{ padding: 0, margin: 0 }}>
                                    {/* <div style={{ margin: "10px 15px" }}>{splicingTable}</div> */}
                                    {splicingTable}
                                </Row>
                            </Col>
                        </Row>}
                </Card.Body>
            </Card >
            <br />
        </>
    )
}

function thereIsPredictionData(infoCd: any): boolean {
    if ( // metapredictors --------------------------------------
        infoCd.REVELSCORE || infoCd.REVELSCORE || infoCd.BAYESDEL_ADDAF_SCORE || infoCd.BAYESDEL_NOAF_SCORE ||
        // predictors --------------------------------------
        infoCd.SIFT_SCORE || infoCd.POLYPHEN2_HDIV_PRED || infoCd.POLYPHEN2_HDIV_SCORE || infoCd.FATHMM_SCORE ||
        infoCd.DANN_SCORE || infoCd.LRT_SCORE || infoCd.LRT_PRED || infoCd.M_CAP_SCORE || infoCd.M_CAP_PRED ||
        infoCd.PROVEAN_SCORE || infoCd.PROVEAN_PRED || infoCd.VEST4_SCORE ||
        // conservation --------------------------------------
        infoCd.PHYLOP || infoCd.PHASTCONS || infoCd.GERP_RS ||
        // splicing --------------------------------------
        infoCd.SAI_DS_AG || infoCd.SAI_DS_AL || infoCd.SAI_DS_DG || infoCd.SAI_DS_DL || infoCd.SAI_DP_AG ||
        infoCd.SAI_DP_AL || infoCd.SAI_DP_DG || infoCd.SAI_DP_DL || infoCd.SAI_DIST
    ) return true;
    return false;
}

export { thereIsPredictionData }