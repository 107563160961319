import React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import FilterPanel from './FilterPanel/FilterPanel';
import QueryBody from './QueryBody/QueryBody';
import Paginator from '../../../Common/Paginator/Paginator';
import { VariantsOrderByValueT } from '../../../../utils/BioFunctionalData';
import QueryContext from '../../../../Contexts/Query/QueryContext';
import { getWolePageHeight } from '../../../../utils/helpers';
import FreezeDiv from '../../../Common/FreezeDiv';

interface QueryHomeI {
    history: any,
    caseId: number,
}

export default function QueryHome(props: QueryHomeI) {
    const { studyId, queryVariants, hpoPhenotypes, queryCombos, tabSelected, scrollYPosition, 
        dispatch: {
            getQueryVariants, refreshQueryVariants, cleanQueryVariants, getPhenotypes, getQueryCombos, setBadgesFiltersData,
            parseFilters_RequestToState, AddFiltersTabs, CleanFiltersBadgesAndTable,
            setFiltersTabSelectedAndSessionS, SetFiltersTabSelected, getFiltersTabsFromSessionS,
            getActualTabs, getActualTabSelected, setQueryVariantsFromTabs, SetScrollYPosition
        }
    } = useContext(QueryContext);

    const getUrlSplitFiltersParams = (): { splitted: any[], params: string } => {
        const splittedUrl = props.history.location?.pathname.split("/") ?? []
        const urlFilterParams = splittedUrl[5] === "newQuery" ? "" : splittedUrl[5].substring(0, splittedUrl[5].toLowerCase().indexOf("&studyid"))
        return { splitted: splittedUrl, params: urlFilterParams }
    }

    let initFilterCollapsed: any = getUrlSplitFiltersParams().params.trim();
    if (initFilterCollapsed === "" || initFilterCollapsed.toLowerCase().indexOf("newquery") > -1)
        initFilterCollapsed = false
    else initFilterCollapsed = true
    const [filterCollapsed, setFilterCollapsed] = useState(initFilterCollapsed);

    const paginator = Paginator({
        id: "advancedQuerys",
        name: "advancedQuerys",
        reloadCall: () => { },
        onChangePage: (i: any) => {
            handleGetQueryVariant(i + 1);
            paginator.setActualPage(i)
        },
        onItemsPerPageChange: (i: any) => {
            handleGetQueryVariant(1, i);
            paginator.pageSize_set(i)
        },
        pageSizeOptions: [25, 50, 100]
    })


    //-----------------------------Filters tabs -----------------------------------------
    function handleCleanUrl() {
        props.history.replace({ pathname: `/cases/query/caseId/${props.caseId}/` });
        const ftss = getFiltersTabsFromSessionS();
        if (ftss.selected) {
            SetFiltersTabSelected("")
        }
    }
    function handleFiltersTabSelected(id: number, e?: any) {
        CleanFiltersBadgesAndTable()
        if (+id !== tabSelected) {
            setFiltersTabSelectedAndSessionS(id)
            const ftss = getFiltersTabsFromSessionS();
            let filter = ftss.tabs.find((y: any) => y.id.toString() === id.toString())
            if (filter) {
                if (filter.urlData.trim() !== "") {
                    setQueryVariantsFromTabs(+id);
                    props.history.push(`${filter.urlData}&studyId=${props.caseId}`)
                } else {
                    refreshQueryVariants();
                    props.history.push(`${filter.urlData}newQuery`)
                }
            }
            paginator.pageSize_set(0)
            paginator.setActualPage(0)
        }
    }

    function handleLoadFilterTab(urlData: string, filterName: string, id: number, description?: string) {
        CleanFiltersBadgesAndTable()
        const studyTabs = getActualTabs() ?? [];
        if (!studyTabs.find((x: any) => `${x.id}` === `${id}`)) {
            const indexOf = urlData.toLowerCase().indexOf("&studyid")
            if (indexOf !== -1) urlData = urlData.substring(0, indexOf)
            AddFiltersTabs(urlData, filterName, id, description)
            setFiltersTabSelectedAndSessionS(id)
        }
    }
    function handleCreateFilter(urlData: string) {
        setFilterCollapsed(false)
        const studyTabs = getActualTabs() ?? [];
        let id: any = 1;
        // eslint-disable-next-line no-loop-func
        while (studyTabs.find((x: any) => x.id === id)) { id += 1 }
        const filterName = `Query: ${id}`
        handleLoadFilterTab(urlData, filterName, id)
        if (urlData.trim() === "") {
            props.history.replace({ pathname: `/cases/query/caseId/${props.caseId}/newQuery` })
            cleanQueryVariants();
            paginator.setActualPage(0)
            paginator.pageSize_set(0)
        }
    }

    //-----------------------------Filters tabs -----------------------------------------    
    const launchSetFiltersTabs = () => {
        const url = getUrlSplitFiltersParams()
        const ftss = getFiltersTabsFromSessionS();
        if (ftss.tabs && ftss.selected && url.splitted && url.splitted.length > 3) {
            setFiltersTabSelectedAndSessionS(+ftss.selected.id)
            if (ftss.tabs.length > 0 && url.params.length > 1) {
                SetFiltersTabSelected(url.params)
            } else {
                SetFiltersTabSelected(ftss?.selected?.urlData)
                if ((ftss?.selected?.urlData ?? "").trim().length > 0) {
                    props.history.push(`${ftss?.selected?.urlData}&studyId=${props.caseId}`)
                } else {
                    props.history.push(`newQuery`)
                }
            }
        }
    }

    const handleGetQueryVariant = (pageNumber?: number, pageSize?: number, then?: (any)) => {
        const url: any = getUrlSplitFiltersParams();
        let orderBy: any = localStorage.getItem(`query_orderBy`) ?? undefined;
        if (orderBy) orderBy = JSON.parse(orderBy)
        orderBy = orderBy ? Array.isArray(orderBy) ? orderBy.map((x: string) => x as VariantsOrderByValueT) : [orderBy as VariantsOrderByValueT] : undefined;
        setBadgesFiltersData()
        if (url.params && url.params.trim() !== "" && url.params !== "newQuery") {
            getQueryVariants(`${url.params}&studyId=${studyId}`,
                pageNumber ?? paginator.actualPage + 1,
                pageSize ?? paginator.itemsPerPage,
                "ASC",
                orderBy).then(() => { if (then) then() })
        } else CleanFiltersBadgesAndTable()
    }
    useEffect(() => {                            // cargamos hpoPhenotypes y lanzamos el seteo de los filtersTabs
        if (hpoPhenotypes.loaded) return;
        getPhenotypes();
        launchSetFiltersTabs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {                            //Con este useEffect cargamos queryCombos para habilitar los combos del panel (<FilterPanel...) 
        if (queryCombos.loaded) return;
        const url: any = getUrlSplitFiltersParams();
        if (!queryCombos.loaded && !queryCombos.loading && !queryCombos.error) getQueryCombos(props.caseId)
        const ftss = getFiltersTabsFromSessionS();
        if (ftss.selected && ftss.selected.urlData !== url.params) launchSetFiltersTabs()
        if (url.splitted.length === 6 && url.splitted[5].length > 3) {
            if (url.params !== "newQuery" && url.params !== "") {
                if (ftss.tabs.length === 0 && !ftss.selected) handleCreateFilter(url.splitted[5])
                refreshQueryVariants()
            } else CleanFiltersBadgesAndTable()
        } else setTimeout(() => { setFilterCollapsed(false) }, 500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])
    useEffect(() => {                                                               // Una vez cargadas los variantes (useEffect de arriba), cargamos la paginacion
        let isMounted = true;
        paginator.pageSize_set(0)
        if (isMounted && queryVariants.refresh) {
            handleGetQueryVariant(1);   //return?
        };
        const url: any = getUrlSplitFiltersParams();
        if (isMounted && url.params && url.params.trim() !== "" && url.params !== "newQuery") {
            paginator.pageSize_set(queryVariants.data?.totalVariantsCount ?? 0)
            paginator.setActualPage((queryVariants.data?.pageNumber ?? 1) - 1)
            setFilterCollapsed(true)
            parseFilters_RequestToState(url.params)
            setBadgesFiltersData();
        } else {
            if (isMounted && !queryVariants.refresh) {
                if (isMounted && queryVariants.loaded && queryVariants.data) {
                    paginator.pageSize_set(queryVariants.data?.totalVariantsCount ?? 0)
                    paginator.setActualPage((queryVariants.data?.pageNumber ?? 1) - 1)
                }
                if (isMounted && getActualTabSelected()?.urlData.toLowerCase().trim() !== url.params.toLowerCase().trim()) {
                    parseFilters_RequestToState(url.params);
                }
                else console.log("-----") // parseFilters_RequestToState(getActualTabSelected()?.urlData ?? "")
                setBadgesFiltersData();
                if (isMounted && (url.params.toLowerCase().indexOf("newquery") !== -1 || url.params.trim() !== "")) {
                    setFilterCollapsed(true)
                }
                return
            };
            paginator.pageSize_set(0)
            setTimeout(() => {
                setFilterCollapsed(false)
            }, 250)
        }
        
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryVariants])
    useEffect(() => {                                                               // Una vez cargadas los variantes (useEffect de arriba), cargamos la paginacion
        let i = 0;
        const handleScrollToLastPosition = () => {
            i += 1;
            setTimeout(() => {
                const pageHeight = getWolePageHeight()
                if (i < 10 && (scrollYPosition.height && scrollYPosition.height !== pageHeight)) handleScrollToLastPosition();
                else if (scrollYPosition.y && scrollYPosition.height && scrollYPosition.height === pageHeight) {
                    // console.log("scroolling")
                    window.scrollTo(0, scrollYPosition.y)
                    SetScrollYPosition(null)
                }
            }, 250);
        }
        handleScrollToLastPosition()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollYPosition])

    const filtersTabs = getActualTabs() ?? []
    const filtersTabSelected = getActualTabSelected()

    const queryBodyComponent = useMemo(() => <QueryBody history={props.history}
        filterCollapsed={filterCollapsed}
        setFilterCollapsed={setFilterCollapsed}
        paginator={paginator}
        filtersTabs={filtersTabs}
        filtersTabSelected={filtersTabSelected?.id}
        handlefiltersTabSelected={handleFiltersTabSelected}
        handleCreateFilter={handleCreateFilter}
        handleReloadDataTable={() => { refreshQueryVariants() }}
        handleGetQueryVariant={(pageNumber?: number, pageSize?: number, then?: any): void => {
            handleGetQueryVariant(pageNumber, pageSize, then)
        }}
        setScrollYPosition={SetScrollYPosition}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    />, [queryVariants.data, paginator])

    return (
        <div id="QueryHome" style={{ display: "flex", margin: "10px 0 0 0" }}>
            {queryCombos.loaded && hpoPhenotypes.loaded ?
                <>
                    <div style={{ display: "flex", position: "fixed", margin: "0 0 0 -22px", zIndex: 3 }}>
                        {queryVariants.loading && <FreezeDiv />}
                        <FilterPanel history={props.history}
                            filterCollapsed={filterCollapsed}
                            setFilterCollapsed={setFilterCollapsed}
                            handleReload={() => { }}
                            handleLoadFilter={(a: string, b: string, c: number, d?: string) => { handleLoadFilterTab(a, b, c) }}
                            filtersTabs={filtersTabs}
                            handleCreateFilter={handleCreateFilter}
                            handleCleanAllFilters={(whitUrl: boolean = false) => {
                                CleanFiltersBadgesAndTable();
                                if (whitUrl) handleCleanUrl();
                                paginator.pageSize_set(0)
                                paginator.setActualPage(0)
                            }}
                        />
                    </div>
                    <>
                        {queryBodyComponent}
                    </>
                </> :
                <div style={{ display: "flex", position: "fixed", margin: "0 0 0 0px", zIndex: 3 }}>
                    <FreezeDiv />
                    {queryBodyComponent}
                </div>
            }
        </div >

    )
}