import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import Paginator from "../../Common/Paginator/Paginator";
import HomeLayout from "../../Layout/Home/HomeLayout";
import { Card, Row, Table } from 'react-bootstrap';
import { LangContext } from "../../../Contexts/Lang";
import Loader from "../../Common/Loader/Loader";
import HomeToolkit from "../../Layout/Home/HomeToolkit";
import ReportService from "../../../Services/ReportService";
import CnvTable from "./CnvTable";
import CnvTableHeaders from "./CnvTableHeaders";
import React from "react";
import HomeFilter, { InputFilterI } from '../../Layout/Home/HomeFilter';
import CnvTitle from "./CnvTitle";
import { useWindowSize } from "src/Helpers/WindowsSizeHelper";
import CNVsContext from "src/Contexts/CNVs/CNVsContext";
import FilterPanelHeader from "./FilterPanelHeader";

interface CnvBodyI {
	history: any,
	caseId: string,
}
export default function CnvBody(props: CnvBodyI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { cnvs, filters, sortBy, filterAndSortCnvDataTable, dispatch: {
		getCNVs, setFilters, cleanFilters, handleSort } } = useContext(CNVsContext);
	const ReportS = new ReportService()
	const { windowSize } = useWindowSize();
	const [cnvsDataTable, setcnvsDataTable] = useState<any[] | null>(null);
	const [filtersCaller, setfiltersCaller] = useState<number>(0);
	const handleFilterCaller = () => {
		paginator.setActualPage(0); setfiltersCaller(filtersCaller + 1)
	}
	const [filteredCount, setfilteredCount] = useState<number | null>(null);
	const tableColumnRef: any = useRef()
	const [rowChildren, setrowChildren] = useState<number[]>([]);
	const [lastChildUpdated, setlastChildUpdated] = useState(false);
	const tableHeaderRef: any = useRef()
	const [headerHeight, setheaderHeight] = useState(0);
	const tableCardTableBodyRef: any = useRef()

	const sortByOptions: any = [
		{ label: translate('common.region'), value: "region" },
		{ label: translate('common.sample'), value: "sample" },
		{ label: translate('common.gene'), value: "gene" },
		{ label: `${translate('common.assessment')} ONCOCNV`, value: "onco" },
		{ label: `${translate('common.assessment')} DECoN`, value: "decon" }
	]

	function handleSortByFilter(value: string) {
		if (value !== sortBy) {
			paginator.setActualPage(0)
			handleSort(value)
			setlastChildUpdated(false)
		}
	}

	function handleCleanFilter() {
		cleanFilters(); handleFilterCaller();
	}

	function handleGetCnvs(page?: number, pageSize?: number, then?: any): void {
		getCNVs(+props.caseId, page ?? paginator.actualPage + 1, pageSize ?? paginator.itemsPerPage)
	}

	const paginator = Paginator({
		reloadCall: () => { },
		onChangePage: (i: any) => { },
		onItemsPerPageChange: (i: any) => { }
	})

	useEffect(() => {
		let isMounted = true;
		if (isMounted && !cnvs.loaded && !cnvs.loading) {
			setlastChildUpdated(false)
			handleGetCnvs(1, 25);
		}
		return () => { isMounted = false };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		let isMounted = true;
		if (isMounted && !cnvs.loading && cnvs.loaded) {
			setlastChildUpdated(false)
			setrowChildren([])
			let arrayCnvs = filterAndSortCnvDataTable(cnvs.data?.cnv?.results, filters, sortBy, cnvs.data?.diseaseGenes);
			// if ((cnvs.datqq:qValuea?.cnv?.results ?? []).length ?? 0 !== arrayCnvs.length)
			if ((cnvs.data?.cnv?.results ?? []).length ?? 0 !== arrayCnvs.length)
				setfilteredCount(arrayCnvs.length);
			else setfilteredCount(null);
			const cnvsPaginated = (arrayCnvs).filter((x: any, i: number) =>
				i >= paginator.actualPage * paginator.itemsPerPage &&
				i < (paginator.actualPage + 1) * paginator.itemsPerPage
			)
			paginator.pageSize_set(arrayCnvs.length)
			setcnvsDataTable(cnvsPaginated)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cnvs, paginator.actualPage, paginator.itemsPerPage, sortBy, filtersCaller])

	useLayoutEffect(() => {
		if (!lastChildUpdated) return
		let childs = tableColumnRef.current?.children
		if (childs) {
			setrowChildren(childs);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastChildUpdated, windowSize]);
	useEffect(() => {
		if (!rowChildren || rowChildren.length === 0) return
		let height = tableHeaderRef.current?.offsetHeight
		if (height) { setheaderHeight(height + 10); }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowChildren]);

	const handleScrollTableBody = (scroll: any): void => {
		const thScrollLeft = tableHeaderRef?.current?.scrollLeft;
		if (thScrollLeft || thScrollLeft === 0) tableHeaderRef.current.scrollLeft = scroll.target.scrollLeft;
	};
	const handleScrollHeadersTable = (scroll: any): void => {
		const tbScrollLeft = tableCardTableBodyRef?.current?.scrollLeft;
		if (tbScrollLeft || tbScrollLeft === 0) tableCardTableBodyRef.current.scrollLeft = scroll.target.scrollLeft;
	};
	let correlation, nComp;
	if ((cnvs.data?.cnv?.results ?? undefined) && (cnvs.data?.cnv?.results ?? []).length > 0) {
		const firstValue = cnvs.data?.cnv?.results[0];
		correlation = firstValue.correlation;
		// console.log(correlation);
		if (correlation !== null && !isNaN(+(correlation))) correlation = (+correlation).toFixed(3);
		nComp = firstValue.nComp;
	}
	const filterPanelHeader = !cnvs.loaded ?
		<></> :
		<FilterPanelHeader genomeReference={cnvs.data?.study?.genomeReference}
			samples={cnvs.data?.study?.samples}
			genesList={cnvs.data?.genes_list ?? null}
			correlation={correlation}
			nComp={nComp}
		/>
	const inputFilterData: InputFilterI[] = [
		{
			type: "text",
			stateName: "genes",
			placeholder: translate("common.filterByGeneName"),
		},
		{
			type: "number",
			stateName: "deconBF",
			placeholder: "  10",
			group: "Decon - Bayes Factor ≥ ",
			defaultValueOnClick: "10"
		},
		{
			type: "number",
			stateName: "oncoQValue",
			placeholder: "  0.01",
			group: "ONCoCNV - qValue ≤ ",
			defaultValueOnClick: "0.01"
		},
		{
			type: "button",
			stateName: "diseaseGenes",
			placeholder: "OMIM",
			// group: "ONCoCNV - qValue ≤ ",
			defaultValueOnClick: ""
		}
	]

	return (
		<>
			<HomeLayout history={props.history}
				filterTitle={<CnvTitle history={props.history} cnvsReq={cnvs} />}
				rowsAmount={{
					loading: !cnvs.loaded || cnvs.loading,
					amount: filteredCount ?? 0 // amount: (cnvsReq.data?.cnv?.results ?? []).length ?? 0
				}}
				Filters={<div style={{ width: "100%" }}>
					<HomeFilter
						filters={filters}
						setFilter={setFilters}
						handleCleanFilter={handleCleanFilter}
						callFilter={() => { handleFilterCaller() }}
						inputFilters={inputFilterData}
						header={!cnvs.loaded || cnvs.loading ? undefined : filterPanelHeader} />
				</div>}
				FiltersEmpty={
					<div style={{ width: "100%" }}>
						<HomeFilter inputFilters={inputFilterData}
							header={filterPanelHeader} />
					</div>
				}
				Toolkit={
					<>
						<HomeToolkit rowData={(cnvs.data?.cnv?.results ?? [])}
							sortBy={{
								value: sortBy,
								options: sortByOptions,
								handleSortBy: handleSortByFilter
							}}
							exportActions={[
								{
									label: `${translate("common.allVariantsInResult")} (xls)`,
									href: ReportS.get_downloadCnvToCsv_link(props.caseId)
								}
							]} />
						{
							(cnvsDataTable ?? []).length !== 0 &&
							<Row ref={tableHeaderRef}
								onScrollCapture={(e: any) => { if (handleScrollHeadersTable) handleScrollHeadersTable(e); }}
								style={{ margin: 0, width: "100%", overflow: "auto hidden" }}>
								<Table responsive="md" style={{ marginBottom: "-1px", width: "100%" }} className="selectTable">
									<CnvTableHeaders study={cnvs.data?.study}
										handleSortByFilter={handleSortByFilter}
										rowChildren={rowChildren}
									/>
								</Table>
							</Row>
						}
					</>
				}
				Table={
					< Card style={{ borderRadius: "0px", marginTop: headerHeight ?? "10px" }}>
					{/* < Card style={{ borderRadius: "0px", marginTop: 5 }}> */}
						<Card.Body ref={tableCardTableBodyRef}
							onScrollCapture={(e: any) => { if (handleScrollTableBody) handleScrollTableBody(e); }}
							style={{ padding: "0px", overflow: "auto hidden" }}>
							{(cnvs.loaded && cnvsDataTable !== null && (cnvsDataTable ?? []).length === 0) ?
								<div>
									<p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
										{translate('common.noResultsFound')}
									</p>
								</div> :
								(!cnvs.loaded && cnvs.loading) || cnvsDataTable === null ?
									<Loader onContainer={true} type={'DNA'} /> :
									<>
										<CnvTable history={props.history}
											study={cnvs.data?.study}
											hugoGenes={cnvs.data?.hugoGenes}
											loading={cnvs.loading}
											dataTable={cnvsDataTable}
											handleSortByFilter={handleSortByFilter}
											handleReload={() => { }}
											studyId={`${props.caseId}`}
											rowRef={tableColumnRef}
											lastChildUpdated={lastChildUpdated}
											setlastChildUpdated={setlastChildUpdated}
										/>
									</>
							}
						</Card.Body>
						{
							(cnvsDataTable ?? []).length > 0 ?
								<Card.Footer>
									{paginator.component}
								</Card.Footer>
								: <></>
						}
					</Card >
				}
			/>
		</>
	)
}
