import React from 'react';
import { Table } from 'react-bootstrap';
import "../../Common/table.css"
import CasesTableRow from './CasesTableRow';
import { StudyI } from 'src/Contexts/interfaces';


type CasesTableT = "default" | "domainDetail"
interface CasesTableI {
    history: any,
    cases: StudyI[],
    casesSelecteds: any,
    handleCasesSelecteds: any,
    handleShowProcessCase(id: number, domainId?: string): void,
    handleShowStatistics?(study?: StudyI, domain?:number): void,
    handleReload(): void,
    CTType?: {
        type: CasesTableT,
        data: any
    }
}
export default function CasesTable(props: CasesTableI) {
    const rows = (props.cases ?? []).map((x: any, i: number) =>
        <CasesTableRow key={i}
            actualRow={x}
            casesSelecteds={props.casesSelecteds}
            handleCasesSelecteds={props.handleCasesSelecteds}
            handleReload={props.handleReload}
            handleShowProcessCase={props.handleShowProcessCase}
            handleShowStatistics={props.handleShowStatistics}
            history={props.history}
            CTType={props.CTType}
        />
    )
    return (
        <div style={{}}>
            <Table responsive="xs"
                className="selectTable"
                style={{ marginBottom: "-1px", overflowY: "hidden" }}>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        </div>
    )
}
export type { CasesTableT }