import libraryy from './resources/library.json';
import preprodDomains from './resources/preprodDomains.json';
import prodDomains from './resources/prodDomains.json';
type EnvironmentT = "PROD" | "PREPROD";
interface DomainI {
    id: number;
    name: string;
    description: string;
    alias: string[];
}

interface OutputRowI {
    studyName: string;
    code: string;
    batch: string;
    studyDescription: string;
    professional: string;
    institution: string;
    genomeReference: string;
    user: number;
    domainId: string;
    libraryPrepKit: string;
    customLibrary: string;
    ttype: string;
    hasBam: string;
    hasCnv: string;
    subtype: string;
    filesSamples: string;
}


export default class BulkLoadBuilder {
    private inputTsvString: string;
    private environment: EnvironmentT;
    private inputCsvColumns: string[];
    private outputCsvColumns: string[];
    private pipelineTypes: any;
    private preprod_domains: DomainI[] = [];
    private prod_domains: DomainI[] = [];
    private library: any = [];
    constructor(inputTsvString: string, environment: EnvironmentT) {
        this.inputTsvString = inputTsvString.replaceAll("\"", "").replaceAll(",", " ");
        this.environment = environment
        this.inputCsvColumns = 'Tanda\tBATCH\tCodigo de proyecto\tCodigo Bitgenia\tNombre y Apellido\tDominio\tPrioridad\tEstado\tCNV\tSERVER\tProducto\tCarga en plataforma\tEstado de Análisis de Casos\tNotificado\tNotificado a:\tDetalle de estudio/panel\tpipeline\tBED\tBED Custom'.split('\t');
        this.outputCsvColumns = ['studyName', 'code', 'batch', 'studyDescription', 'professional', 'institution', 'genomeReference', 'user', 'domainId', 'libraryPrepKit', 'customLibrary', 'type', 'hasBam', 'hasCnv', 'subtype', 'fileSamples'];
        this.pipelineTypes = {
            //procesamiento_sheet://b_platform_request, 
            "genotyping array": "ARRAY",
            "mitochondrial": "MT-DNA",
            "exome": "WES",
            "genome": "WGS",
            "panel": "PANEL",
            "somatic": "SOMATIC",
        }
        this.resourcesLoader();
    }
    private resourcesLoader() {
        this.preprod_domains = preprodDomains.map((x: any): DomainI => ({
            id: x.id,
            name: x.name,
            description: x.description,
            alias: x.alias,
        }));
        this.prod_domains = prodDomains.map((x: any): DomainI => ({
            id: x.id,
            name: x.name,
            description: x.description,
            alias: x.alias,
        }));
        this.library = libraryy;
    }

    private loadProcesamientoSheet(): {}[] {
        const procesamientoSheetData: {}[] = []
        const rows = this.inputTsvString.split('\n').filter((x: string) => x.trim() !== "");
        for (let i = 0; i < rows.length; i++) {
            let newObj: any = {}
            const rowValues = rows[i].split('\t')
            if (rowValues.length !== this.inputCsvColumns.length)
                throw new Error("Invalid input format: row " + (i + 1) + " has " + rowValues.length + " columns but should have " + this.inputCsvColumns.length)
            for (let ii = 0; ii < rowValues.length; ii++) {
                newObj[this.inputCsvColumns[ii]] = rowValues[ii];
            }
            procesamientoSheetData.push(newObj);
        };
        // console.log(procesamientoSheetData);
        return procesamientoSheetData
    }

    private common_cleaner(value: string) {
        return value.replace(",", " ")
    }
    private common_validator(row: any, field: string) {
        if (row[field] && String(row[field]).trim() !== "" && String(row[field]).trim() !== "#REF!")
            return true
        return false
    }

    private _get_environment(row: any): EnvironmentT {
        const production_values = ["LICENCIA", "SEC+LIC"]
        for (let x in production_values) {
            // console.log(row)
            // console.log(row["Producto"])
            if (row["Producto"].includes(production_values[x])) {
                return "PROD"
            }
        }
        return "PREPROD"
    }
    private _get_domain(row: any, environment: string): DomainI | undefined {
        if (row["Dominio"] === "#N/A" || row["Dominio"] === "#REF!") return undefined
        if (environment === "PREPROD") {
            for (let i in this.preprod_domains) {
                for (let ii in this.preprod_domains[i].alias) {
                    if (this.preprod_domains[i].alias[ii].trim().toUpperCase() === row["Dominio"].trim().toUpperCase()) {
                        return this.preprod_domains[i]
                    }
                }
            }
            return this.preprod_domains.filter(domain => domain.id === 126)[0]
        }
        else if (environment === "PROD") {
            for (let i in this.prod_domains)
                for (let ii in this.prod_domains[i].alias)
                    if (this.prod_domains[i].alias[ii].trim().toUpperCase() === row["Dominio"].trim().toUpperCase())
                        return this.prod_domains[i]
            throw new Error("ERROR!!! domain not found: " + row["Dominio"].strip().upper() + " on prodDomains.json alias")
        }
    }
    private get_code(row: any): string {
        const fieldCDP = "Codigo de proyecto"
        const fieldCB = "Codigo Bitgenia"
        let code = ""
        if (this.common_validator(row, fieldCDP))
            code = row[fieldCDP]
        else if (this.common_validator(row, fieldCB))
            code = row[fieldCB]
        else
            throw new Error("ERROR!!! code not found in **" + fieldCDP + "** or **" + fieldCB + "**")
        return code
    }
    private get_studyName(row: any) {
        let studyName = this.get_code(row)
        if (this.common_validator(row, "Nombre y Apellido"))
            studyName = String(studyName) + " - " + String(row["Nombre y Apellido"]).replaceAll("\n", "").replaceAll("\n", " ")
        studyName = this.common_cleaner(studyName)
        return studyName
    }
    private get_batch(row: any) {
        let batch = ""
        if (this.common_validator(row, "BATCH"))
            batch = row["BATCH"]
        else if (this.common_validator(row, "Tanda"))
            batch = row["Tanda"]
        return batch
    }
    private get_studyDescription(row: any, domain: DomainI) {
        //// agregar que si es preprod y row["domain"] != domain["name"] le agregue el nombre de dominio
        let description = ""
        // if (domain["id"] == 126 && row["Dominio"] not in domain["alias"])
        if (domain.id === 126 && !domain.alias.includes(row["Dominio"]))
            description = row["Dominio"]
        const field = "Detalle de estudio/panel"
        if (this.common_validator(row, field) && row[field].length > 0)
            description += (description.length > 0 ? " - " : "") + row[field].replaceAll("\n", "").replaceAll("\n", " ");
        return description
    }

    private get_library(row: any) {
        let libprepkit = this.library.hasOwnProperty(row["BED"]) ? this.library[row["BED"]] : "Custom";
        if (libprepkit === "Custom") {
            const myField = "BED Custom";
            if (this.common_validator(row, myField) && row[myField].length > 0)
                return ["CUSTOM", row[myField]] as [string, string]
            else
                return ["CUSTOM", "-"]
        }
        return [libprepkit, ""]
    }
    private get_cnv(row: any) {
        if (row["CNV"] && (row["CNV"] === "4. procesado" || row["CNV"] === "5. enviado"))
            return "true"
        return "false"
    }
    private get_pipeline(row: any) {
        if (this.pipelineTypes.hasOwnProperty(row["pipeline"]))
            return this.pipelineTypes[row["pipeline"]]
        return "exome";
    }
    private buildOutputData(procesamientoSheetData: {}[]): [OutputRowI[], string[]] {
        const output_array: OutputRowI[] = [];
        const output_code_wrong_environmet: string[] = [];
        for (let i = 0; i < procesamientoSheetData.length; i++) {
            const _environment: EnvironmentT = this._get_environment(procesamientoSheetData[i])//"PROD" or "PREPROD"
            const code = this.get_code(procesamientoSheetData[i])                                         // Required
            if (_environment !== this.environment) {
                output_code_wrong_environmet.push(code);
                continue
            };
            const _domain: DomainI | undefined = this._get_domain(procesamientoSheetData[i], _environment)// Required, catched error
            if (!_domain) throw new Error("ERROR!!! domain not found for stuy Code: " + code)
            const studyName = this.get_studyName(procesamientoSheetData[i]);                              // Required      
            const batch = this.get_batch(procesamientoSheetData[i]);
            const studyDescription = this.get_studyDescription(procesamientoSheetData[i], _domain);
            const professional = "";
            const institution = _domain ? _domain.name : "";
            const genomeReference = "hg38";
            const user = 5;
            const domainId = String(_domain.id);
            const [libraryPrepKit, customLibrary] = this.get_library(procesamientoSheetData[i]);           // Required
            const ttype = "simple";
            const subtype = this.get_pipeline(procesamientoSheetData[i]);                                  // Required      
            const hasBam = "true";
            const hasCnv = this.get_cnv(procesamientoSheetData[i]);
            const filesSamples = code + "_acmg_final_annot.vcf.gz";

            const outputRow: OutputRowI = {
                studyName,
                code,
                batch,
                studyDescription,
                professional,
                institution,
                genomeReference,
                user,
                domainId,
                libraryPrepKit,
                customLibrary,
                ttype,
                hasBam,
                hasCnv,
                subtype,
                filesSamples
            }
            output_array.push(outputRow);
        }
        return [output_array, output_code_wrong_environmet];
    }
    private write_output_string_file(sucessData: OutputRowI[], wrongEnvironmetData: string[] = []): {successData: string, wrongEnvironmetData: string}{
        let outputStringFile = "";
        outputStringFile += this.outputCsvColumns.join(",") + "\n";
        for (let i = 0; i < sucessData.length; i++) {
            const row = sucessData[i];
            const line = Object.values(row).join(",");
            outputStringFile += line + "\n";
        }
        return {successData: outputStringFile, wrongEnvironmetData: wrongEnvironmetData.join(", ")};
    }
    public build(): {successData: string, wrongEnvironmetData: string} {
        const procesamientoSheetData = this.loadProcesamientoSheet();
        const outputData: [OutputRowI[], string[]] = this.buildOutputData(procesamientoSheetData);
        const outputArrayData: OutputRowI[] = outputData[0];
        const output_code_wrong_environmet: string[] = outputData[1];
        const result = this.write_output_string_file(outputArrayData, output_code_wrong_environmet);
        return result;
    }
}

export type { EnvironmentT }
