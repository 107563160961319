import React from 'react';
import CasesTable from '../../Cases/Home/CasesTable';
import { useEffect, useState, useContext } from 'react';
import { Card } from 'react-bootstrap';
import Paginator from '../../Common/Paginator/Paginator';
import { LangContext } from '../../../Contexts/Lang';
import ProcessCase from '../../Cases/ProcessCase/ProcessCase';
import HomeLayout from '../../Layout/Home/HomeLayout';
import HomeFilter, { InputFilterI } from '../../Layout/Home/HomeFilter';
import { faArchive, faExchangeAlt, faFolderOpen, faTrash } from '@fortawesome/free-solid-svg-icons';
import HomeToolkit from '../../Layout/Home/HomeToolkit';
import DomainsContext, { FiltersCasesDomainReqI, FiltersUserDomainReqI } from '../../../Contexts/Domains/DomainsContext';
import Loader from '../../Common/Loader/Loader';
import { studyStateOptions, studyTypeOptions } from '../../../utils/BioFunctionalData';
import ModalChangeStudyDomain, { ModalChangeStudyDomainDataI } from './ModalChangeStudyDomain';
import StudyService from '../../../Services/StudyServices';
import { StudyI } from 'src/Contexts/interfaces';
import ModalStatistics from 'src/Components/Cases/Detail/Statistics/ModalStatistics';

interface DetailDomainCasesI {
    history: any,
    domainId: any,
    filterTitleTabs: JSX.Element,
    handleGetDomain(
        page?: number, pageSize?: number, tabSelected?: "study" | "user", filtersDomain?: FiltersCasesDomainReqI, filtersUsers?: FiltersUserDomainReqI, then?: any
    ): void
}
export default function DetailDomainCases(props: DetailDomainCasesI) {
    const { dispatch: { translate } } = useContext(LangContext);

    const { domain, filters_cases, selectedsCases, dispatch: {
        setFiltersCases, cleanFiltersCases, handleSelectedsCases, handleSelectAllCases } } = useContext(DomainsContext);

    const StudyS = new StudyService()

    const [processCase, setprocessCase] = useState<{ "open": boolean, "id": number | undefined }>({ open: false, id: undefined })
    const handleCloseProcessCase = () => setprocessCase({ open: false, id: undefined });
    const handleShowProcessCase = (id?: number) => { setprocessCase({ open: true, id: id ? id : undefined }) };
    const [showStats, setshowStats] = useState<{ "open": boolean, study: StudyI | undefined, domainId: number | null }>({ open: false, study: undefined, domainId: null })
    const handleCloseStatistics = () => setshowStats({ open: false, study: undefined, domainId: null });
    const handleShowStatistics = (study?: StudyI, domainId?: number) => {
        setshowStats({ open: true, study: study ? study : undefined, domainId: domainId ? domainId : null })
    };

    const [moveStudy, setmoveStudy] = useState<{ open: boolean, data?: ModalChangeStudyDomainDataI }>({ open: false, data: undefined })
    const handleCloseMoveStudy = () => setmoveStudy({ open: false, data: undefined });
    const handleShowMoveStudy = (data: ModalChangeStudyDomainDataI) => { setmoveStudy({ open: true, data: data }) };

    const paginator = Paginator({
        reloadCall: () => { },
        onChangePage: (i: any) => {
            paginator.setActualPage(i)
            props.handleGetDomain(i + 1, paginator.itemsPerPage, "study", filters_cases)
        },
        onItemsPerPageChange: (i: any) => {
            paginator.setActualPage(0)
            props.handleGetDomain(1, i, "study", filters_cases)
        }
    })

    useEffect(() => {
        paginator.pageSize_set(domain.data?.cases?.rowsCount ?? 0)
        paginator.setActualPage((domain.data?.cases?.pageNumber ?? 1) - 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain])

    function handleSortByFilter(value: string) {
        if (value !== filters_cases.sortColumn) {
            handleSelectedsCases("")
            setFiltersCases("sortColumn", value)
            props.handleGetDomain(1, paginator.itemsPerPage, "study", { ...filters_cases, sortColumn: value })
        }
    }

    //----------------------------Cases Filters--------------------------------------
    const inputFilterData: InputFilterI[] = [
        {
            type: "text",
            stateName: "nameSearch",
            placeholder: translate('home.filter.studyNameCodeOrBatch'),
        },
        {
            type: "select",
            stateName: "studyStateSearch",
            options: studyStateOptions(translate),
            placeholder: translate("common.states")
        },
        {
            type: "date",
            stateName: "dateCreatedFromSearch",
            placeholder: translate('home.filter.createdFrom'),
        },
        {
            type: "date",
            stateName: "dateCreatedToSearch",
            placeholder: translate('home.filter.createdUntil'),
        },
    ]
    //----------------------------Cases Filters--------------------------------------
    return (
        <>
            {moveStudy.open && <ModalChangeStudyDomain show={moveStudy.open}
                handleClose={handleCloseMoveStudy}
                handleReload={() => {
                    props.handleGetDomain(1, paginator.itemsPerPage, "study", filters_cases)
                    handleSelectedsCases("")
                }}
                data={moveStudy.data}
            />}
            <ModalStatistics showStats={showStats}
                handleCloseStatistics={handleCloseStatistics}
                history={props.history} />

            <ProcessCase show={processCase.open}
                handleClose={handleCloseProcessCase}
                handleReload={() => {
                    props.handleGetDomain(1, paginator.itemsPerPage, "study")
                }}
                caseId={processCase.id}
                studyTypeOptions={studyTypeOptions(translate)}
                domainId={props.domainId}
                domainName={domain.data?.domain?.name}
                CTType="domainDetail"
            />
            <HomeLayout history={props.history}
                HLType="domainDetail"
                filterTitleCustom={props.filterTitleTabs}
                rowsAmount={{
                    loading: !domain.loaded || domain.loading,
                    amount: domain?.data?.cases?.rowsCount ?? 0,
                }}
                filtersBorder={true}
                Filters={
                    <div style={{ width: "100%" }}>
                        <HomeFilter HLType="domainDetail"
                            filters={filters_cases}
                            setFilter={setFiltersCases}
                            handleCleanFilter={() => {
                                cleanFiltersCases();
                                props.handleGetDomain(1, paginator.itemsPerPage, "study")
                            }}
                            callFilter={() => {
                                props.handleGetDomain(1, paginator.itemsPerPage, "study", filters_cases)
                            }}
                            inputFilters={inputFilterData}
                        />
                    </div>
                }
                FiltersEmpty={
                    <div style={{ width: "100%" }}>
                        <HomeFilter inputFilters={inputFilterData} HLType="domainDetail" />
                    </div>
                }
                Toolkit={
                    <HomeToolkit
                        rowData={(domain.data?.cases?.results ?? [])}
                        rowDataSelected={selectedsCases}
                        handleSelectAll={handleSelectAllCases}
                        multipleActionsOptions={[
                            {
                                icon: faArchive,
                                label: translate("common.archive"),
                                handleClick: () => {
                                    StudyS.archive(selectedsCases.map((x: string) => +x)).then(() => {
                                        handleSelectedsCases("")
                                        cleanFiltersCases()
                                        props.handleGetDomain(1, paginator.itemsPerPage, "study", filters_cases)
                                        // props.handleReload();
                                    })
                                },
                                modalWarning: {
                                    label: translate('home.tableManager.confirmArchiveAllSelectedStudy'),
                                    buttonLabel: translate('common.archive')
                                }
                            },
                            {
                                icon: faFolderOpen,
                                label: translate("common.activate"),
                                handleClick: () => {
                                    StudyS.active(selectedsCases.map((x: string) => +x)).then(() => {
                                        cleanFiltersCases()
                                        handleSelectedsCases("")
                                        props.handleGetDomain(1, paginator.itemsPerPage, "study", filters_cases)
                                    })
                                },
                                modalWarning: {
                                    label: translate('home.tableManager.confirmActiveAllSelectedStudy'),
                                    buttonLabel: translate('common.activate')
                                }
                            },
                            {
                                icon: faExchangeAlt,
                                label: translate("domain.moveTo"),
                                handleClick: () => {
                                    console.log("aaaaaaaaaa")
                                    const domainsOptions = (domain.data?.domains ?? []).map((x: string) => { return { label: x, value: x } })
                                    const studiesSelecteds = selectedsCases.map((x: string) => {
                                        let name: any = domain.data.cases.results.filter((y: any) => `${y.id}` === `${x}`);
                                        name = name.length > 0 ? name[0].name : "--";
                                        return { id: x, name: name }
                                    })
                                    handleShowMoveStudy({ domainId: props.domainId, domainOptions: domainsOptions, studies: studiesSelecteds })
                                },
                            },
                            "divider",
                            {
                                icon: faTrash,
                                label: translate('home.delete'),
                                handleClick: () => {
                                    StudyS.delete(selectedsCases.map((x: string) => +x), props.domainId).then(() => {
                                        cleanFiltersCases()
                                        handleSelectedsCases("")
                                        props.handleGetDomain(1, paginator.itemsPerPage, "study", filters_cases)
                                    })
                                },
                                modalWarning: {
                                    label: translate('home.tableManager.confirmDeleteAllSelectedStudy'),
                                    buttonLabel: translate('home.delete')
                                }
                            },
                        ]}
                        addElement={{
                            tooltipLabel: translate('home.tableManager.addCase'),
                            handleCreate: handleShowProcessCase,
                        }}
                        sortBy={{
                            value: filters_cases.sortColumn,
                            options: "default",
                            handleSortBy: handleSortByFilter
                        }}
                    />
                }
                Table={
                    <Card style={{ borderRadius: "0px" }}>
                        <Card.Body style={{ padding: "0px" }}>
                            {!domain.loaded || domain.loading ?
                                <Loader onContainer={true} type={'DNA'} /> :
                                (domain?.data?.cases?.results && domain?.data?.cases?.results.length !== 0) ?
                                    <CasesTable cases={domain.data.cases.results as StudyI[]}
                                        casesSelecteds={selectedsCases}
                                        handleCasesSelecteds={handleSelectedsCases}
                                        handleShowProcessCase={handleShowProcessCase}
                                        handleShowStatistics={handleShowStatistics}
                                        handleReload={() => {
                                            props.handleGetDomain(1, paginator.itemsPerPage, "study", filters_cases)
                                        }}
                                        history={props.history}
                                        CTType={{
                                            type: "domainDetail",
                                            data: { domainId: props.domainId }
                                        }}
                                    />
                                    :
                                    <div>
                                        <p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
                                            {translate('home.noCasesFound')}
                                        </p>
                                    </div>
                            }
                        </Card.Body>
                        {(domain?.data?.cases?.results ?? []).length > 0 ?
                            <Card.Footer>
                                {paginator.component}
                            </Card.Footer>
                            : <></>}
                    </Card>
                }
            />
        </>
    )
}
